import Axios from "axios"
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router'
import '../assets/style/Login.css';
import { useAuth } from '../hooks';
import schipt_logo from '../assets/Schipt_black.png';
import schipt_logo_white from '../assets/Schipt_white.png';
import { Link } from 'react-router-dom';
import { useTranslate } from '../hooks';
import { Switch, TextField, Button, Container, Grid, Centralizer, Form } from '../components';
// import BASE_URL from "../utils/Variables/baseURL"


export default function Login() {
  const { auth, setAuth } = useAuth()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  // let password = useRef<any>()
  const [errMsg, setErrMsg] = useState('');
  const [ disabled, setDisabled ] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslate()
  const [ theme ] = useState<"dark" | "light">(localStorage.theme)

  useEffect(() => {
    if (!auth || auth?.accessToken === undefined)
      return
    navigate("/Shipments")
  }, [auth, navigate])

  useEffect(() => {
    console.log(location)
    if ((location.state as any)?.username) {
      // eslint-disable-next-line
      setUsername((location.state as any)?.username)
      // eslint-disable-next-line
      setPassword("")
    }
  }, [(location.state as any)?.username])

  useEffect(() => {
    setErrMsg('')
  }, [username, password])

  const loginUser = async (e: any) => {    
    e.preventDefault();
    setDisabled(true)
    Axios.post(`${process.env.REACT_APP_BASE_URL}/login`, {
      username: username,
      password: password
    },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
      })
      .then(({ data }) => {
        if (setAuth) {
          setAuth({
            username: username,
            accessToken: data.accessToken
          });
        }
        setDisabled(false)

        // if (location?.state?.from){
        //   return navigate(location.state.from) 
        // }        
        // else
        //   return navigate("/Home")

      })
      .catch((e) => {
        console.error(e)
        setErrMsg(e?.response?.data?.message ?? e.message)
        setDisabled(false)
      })
  }

  return (<>
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 bg-schipt-white dark:bg-schipt-darkest">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src={theme === 'dark' ? schipt_logo_white : schipt_logo}  alt="logo" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-schipt-black dark:text-schipt-white-90 font-montserrat">{t('SignInAccount')}</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-schipt-white dark:bg-schipt-darkest">
        <Container className="mx-auto max-w-md">
          <Container>
            <Form onSubmit={(e) => loginUser(e)}>
              <TextField className="w-full mb-5" value={username} onChange={(e) => setUsername(e.target.value)} _type="email" autoFocus required>Email</TextField>
              <TextField className="w-full mb-4"  value={password} onChange={(e) => setPassword(e.target.value)} _type="password" required autoComplete="off">{t('Password')}</TextField>
              <Button _type="submit" className='schipt-btn primary flex w-full' disabled={disabled ?? undefined}>Login</Button>
            </Form>
          </Container>
          <p className={errMsg ? "errmsg" : "off-screen"} style={{ color: "red", fontWeight: "bold" }} aria-live="assertive">{errMsg}</p>
          <Grid style={{ justifyContent: "space-between" }}>
            <Link to="/Register" className="font-semibold text-schipt-black dark:text-schipt-white-90 hover:text-schipt-green dark:hover:text-schipt-green">{t('CreateAccount')}</Link>
            <Link to="/ResetPassword" state={{ username }} className="font-semibold text-schipt-black dark:text-schipt-white-90 hover:text-schipt-green dark:hover:text-schipt-green">{t('ForgotPassword')}</Link>
          </Grid>
        </Container>
        {/* <Toast autoHideDuration={4000} type="info" position="topcenter" open={switchTest}>Of course I remember you! 😃</Toast> */}
      </div>
    </div>
  </>)
}